//here originX and originY refers to the center of the rectangle

function Rectangle({originX, originY, width, height, stroke, fill}) {
    const x1 = originX-width/2;
    const y1 = originY-height/2;
    const x2 = originX+width/2;
    const y2 = originY-height/2;
    const x3 = originX+width/2;
    const y3 = originY+height/2;
    const x4 = originX-width/2;
    const y4 = originY+height/2;
    
    return (
        <polygon points={`${x1},${y1} ${x2},${y2} ${x3},${y3} ${x4},${y4}`} 
            stroke={stroke}
            fill={fill}/>
    );
};

export default Rectangle;