//here originX, originY refers to the tip of the arrowhead

function Arrow({originX, originY, tailLength, arrowHeadHeight, stroke, fill, direction}) {
    if(direction == "up") {
        return (
            <>
                <polygon points={`${originX},${originY} ${originX+arrowHeadHeight},${originY+arrowHeadHeight/2} ${originX-arrowHeadHeight},${originY+arrowHeadHeight/2}`}
                    stroke = {stroke}
                    fill = {fill}/>

                <line x1 = {`${originX}`}
                    y1 = {`${originY + arrowHeadHeight}`}
                    x2 = {`${originX}`}
                    y2 = {`${originY+arrowHeadHeight + tailLength}`}
                    stroke={stroke}/>
            </>
        );
    }else if(direction == "down") {
        return (
            <>
                <polygon points={`${originX},${originY} ${originX+arrowHeadHeight},${originY-arrowHeadHeight/2} ${originX-arrowHeadHeight},${originY-arrowHeadHeight/2}`}
                    stroke = {stroke}
                    fill = {fill}/>

                <line x1 = {`${originX}`}
                    y1 = {`${originY - arrowHeadHeight}`}
                    x2 = {`${originX}`}
                    y2 = {`${originY-arrowHeadHeight - tailLength}`}
                    stroke={stroke}/>
            </>
        );
    }else if(direction == "right") {
        return (
            <>
                <polygon points={`${originX},${originY} ${originX-arrowHeadHeight},${originY+arrowHeadHeight/2} ${originX-arrowHeadHeight},${originY-arrowHeadHeight/2}`}
                    stroke = {stroke}
                    fill = {fill}/>

                <line x1 = {`${originX-arrowHeadHeight}`}
                    y1 = {`${originY}`}
                    x2 = {`${originX-arrowHeadHeight-tailLength}`}
                    y2 = {`${originY}`}
                    stroke={stroke}/>
            </>
        );
    }else {
        return (
            <>
                <polygon points={`${originX},${originY} ${originX+arrowHeadHeight},${originY+arrowHeadHeight/2} ${originX+arrowHeadHeight},${originY-arrowHeadHeight/2}`}
                    stroke = {stroke}
                    fill = {fill}/>

                <line x1 = {`${originX+arrowHeadHeight}`}
                    y1 = {`${originY}`}
                    x2 = {`${originX+arrowHeadHeight+tailLength}`}
                    y2 = {`${originY}`}
                    stroke={stroke}/>
            </>
        );
    }
}

export default Arrow;