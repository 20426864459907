import Rectangle from "../elements/rectangle";
import DimensionLine from "../elements/dimensionLine";

function SectionDimensions({frameWidth, frameHeight, width, height, thickness}) {
    const displayWidth = 0.15*frameWidth;
    const displayHeight = 0.5*frameHeight;
    const displayThickness = 10;
    const frameCenterX = frameWidth/2;
    const frameCenterY = frameHeight/2+15;
    
    return (
        <>
            {/*outer rectangle*/}
            <Rectangle originX={frameCenterX}
                originY={frameCenterY}
                width={displayWidth}
                height={displayHeight}
                stroke="black"
                fill="lightGrey" />
            {/*inner rectangle*/}
            <Rectangle originX={frameCenterX}
                originY={frameCenterY}
                width={displayWidth-2*displayThickness}
                height={displayHeight-2*displayThickness}
                stroke="black"
                fill="white" />
            {/*horizontal dimension line for width*/}
            <DimensionLine originX={frameCenterX}
                originY={frameCenterY+displayHeight/2+20}
                spanDirection="horizontal"
                span={displayWidth}
                text={`${width} mm`}
                textAlignment="bottom"
                stroke="black" />
            {/*vertical dimension line for height*/}
            <DimensionLine originX={frameCenterX+displayWidth/2+20}
                originY={frameCenterY}
                spanDirection="vertical"
                span={displayHeight}
                text={`${height} mm`}
                textAlignment="right"
                stroke="black" />
            {/*vertical dimension line for thickness*/}
            <DimensionLine originX={frameCenterX-displayWidth/2-20}
                originY={frameCenterY-displayHeight/2+displayThickness/2}
                spanDirection="vertical"
                span={displayThickness}
                text={`${thickness} mm`}
                textAlignment="left"
                stroke="black" />
        </>
    );
}

export default SectionDimensions;