import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/homePage/homePage.js';
import LoginPage from './pages/loginPage/loginPage.js';
import RectangularHollowSteelBeam from './pages/designPages/rectangularHollowSteelBeam/rectangularHollowSteelBeam.js';
import CircularHollowSteelBeam from './pages/designPages/circularHollowSteelBeam/circularHollowSteelBeam.js';
import RectangularSolidConcreteBeam from './pages/designPages/rectangularSolidConcreteBeam.js/rectangularSolidConcreteBeam.js';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route index element={<HomePage />} />
				<Route path="/loginPage" element={<LoginPage />} />
				<Route path="/rectangularHollowSteelBeam" element={<RectangularHollowSteelBeam />}/>
				<Route path="/circularHollowSteelBeam" element={<CircularHollowSteelBeam />}/>
				<Route path="/rectangularSolidConcreteBeam" element = {<RectangularSolidConcreteBeam />}/>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
