import "./inputSheet.css"

function InputDataEntry({inputDataEntryTitle, inputDataEntryInput, inputDataEntryUnit, updateCalculationsFunction}) {    
    if(inputDataEntryInput[0] == "number") {
        return (
            <div className="_inputDataEntryDiv">
                <label className="_inputDataEntryTitle">{inputDataEntryTitle}</label>
                <input className="_inputDataEntryInput" type={inputDataEntryInput[0]} ref={inputDataEntryInput[1]} defaultValue={inputDataEntryInput[2]} onChange={updateCalculationsFunction}/>
                <label className="_inputDataEntryUnit">{inputDataEntryUnit}</label>
            </div>
        );
    } else if (inputDataEntryInput[0] == "dropdown") {
        return (
            <div className="_inputDataEntryDiv">
                <label className="_inputDataEntryTitle">{inputDataEntryTitle}</label>
                <select className="_inputDataEntryInput" ref={inputDataEntryInput[1]} onChange={updateCalculationsFunction}>
                    {inputDataEntryInput[2].map((value) => (
                        <option>{value}</option>
                    ))}
                </select>
                <label className="_inputDataEntryUnit">{inputDataEntryUnit}</label>
            </div>
        );
    } else {
        return (
            <>
            </>
        )
    }
}

function InputHeading({heading, onClickFunction}) {
    return (
        <div className="_inputHeadingDiv">
            <h1 className="_inputHeading">{heading}</h1>
            <button className="_inputHeadingButton" onClick={onClickFunction}>i</button>
        </div>
    );
}

function InputSheet({inputData, updateCalculationsFunction, informationOverlayFunctions}) {
    //Format for InputData
    //const inputData = {
    //     [key]: ["Heading", "inputDataEntryTitle", ["inputDataEntryInputType", inputDataEntryInputRef, inputDataEntryInputValue], "inputDataEntryUnit"]
    // }

    //Get out all the headings from the calculation data. The "...new Set" method is going to remove any duplicates and keep only the unique ones
    let allHeadings = [];
    Object.keys(inputData).forEach((key) => {
        allHeadings.push(inputData[key][0]);
    })

    let uniqueHeadings = [...new Set(allHeadings)];

    //put the rendered data entries into the temp object for rendering
    const renderedInputSheet = [];

    uniqueHeadings.map((heading, index) => {
        renderedInputSheet.push(<InputHeading heading={heading} onClickFunction={informationOverlayFunctions[index]}/>);
        Object.keys(inputData).forEach((key) => {
            if(inputData[key][0]==heading) {
                renderedInputSheet.push(<InputDataEntry inputDataEntryTitle={inputData[key][1]} inputDataEntryInput={inputData[key][2]} inputDataEntryUnit={inputData[key][3]} updateCalculationsFunction={updateCalculationsFunction}/>)
            }
        })
    })

    return (
        <>
            {renderedInputSheet}
        </>
    );
}

export default InputSheet;