import Arrow from "../elements/arrow"
import Rectangle from "../elements/rectangle"
import CircularArrow from "../elements/circularArrow";
import Legend from "../elements/legend";

function LongitudinalView({frameLength: frameWidth, frameHeight, axialForce, moment}) {
    const length = 0.4*frameWidth;
    const height = 0.1*frameHeight;
    const frameCenterX = frameWidth/2;
    const frameCenterY = frameHeight/2+15;
    const tailLength = 0.2*length;
    const arrowHeadHeight = 0.05*length;
    const legendStartPoint = frameCenterY+height+40;
    const radius = 0.15*length;
    const leftCircleOriginX = frameCenterX-length/2;
    const leftCircleOriginY = frameCenterY;
    const rightCircleOriginX = frameCenterX + length/2;
    const rightCircleOriginY = frameCenterY;
    const legendItems = {
        axialForce: ["blue", "Axial Force"],
        momentAboutX: ["green", "Moment about X"]
    };

    return (
        <>
            {/*main rectangle*/}
            <Rectangle originX={frameCenterX}
                originY={frameCenterY}
                width={length}
                height={height}
                stroke="black"
                fill="none" />

            {/*right arrow*/}
            <Arrow originX={frameCenterX-length/2}
                originY={frameCenterY}
                tailLength={tailLength}
                arrowHeadHeight={arrowHeadHeight}
                stroke="blue"
                fill="blue"
                direction="right"/>

            <text x={`${frameCenterX+length/2+tailLength+arrowHeadHeight+5}`} 
                y={`${frameCenterY}`} 
                fill="blue" 
                dominantBaseline="middle"
                fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">{`${axialForce} kN`}</text>

            {/*right circle*/}
            <CircularArrow originX={rightCircleOriginX}
                originY={rightCircleOriginY}
                radius={radius}
                arrowHeadHeight={arrowHeadHeight}
                stroke="green"
                fill="green"
                rotation="antiClockwise"
                orientation="right"/>

            <text x={`${frameCenterX+length/2-0.05*length}`} 
                y={`${frameCenterY-0.02*length-0.15*length-10}`} 
                fill="green" 
                dominantBaseline="middle" 
                textAnchor="middle"
                fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">{`${moment} kN-m`}</text>   
        
            {/*left arrow*/}
            <Arrow originX={frameCenterX+length/2}
                originY={frameCenterY}
                tailLength={tailLength}
                arrowHeadHeight={arrowHeadHeight}
                stroke="blue"
                fill="blue"
                direction="left"/>

            <text x={`${frameCenterX-length/2-tailLength-arrowHeadHeight-5}`} 
                y={`${frameCenterY}`} 
                fill="blue" 
                dominantBaseline="middle" 
                textAnchor="end"
                fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">{`${axialForce} kN`
                }</text>

            {/* left circle */}
            <CircularArrow originX={leftCircleOriginX}
                originY={leftCircleOriginY}
                radius={radius}
                arrowHeadHeight={arrowHeadHeight}
                stroke="green"
                fill="green"
                rotation="clockwise"
                orientation="left" />

            <text x={`${frameCenterX-length/2+0.05*length}`} 
                y={`${frameCenterY-0.02*length-0.15*length-10}`} 
                fill="green" 
                dominantBaseline="middle" 
                textAnchor="middle"
                fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">{`${moment} kN-m`}</text>

            {/*Legend*/}
            <Legend originX={10}
                originY={legendStartPoint}
                sideLength={10}
                verticalSpacing={20}
                items={legendItems}/>

        </>
    );
}

export default LongitudinalView;