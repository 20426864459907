import "../templates/template1";
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import DesignCapacityAndRatiosTable from "../../../components/tables/designCapacityAndRatios";
import SettingsMenu from "../../../components/settingsMenu/settingsMenu";
import CalculationSheet from "../../../components/calculationSheet/calculationSheet";
import InputSheet from "../../../components/inputSheet/inputSheet";
import MyDocument from "../../../components/pdf/rectangularHollowSteelSection";
import { pdf } from "@react-pdf/renderer";
import TitleBar from "../../../components/titleBar/titleBar";
import InformationOverlay from "../../../components/informationOverlay/informationOverlay";

function RectangularSolidConcreteBeam() {
  //********************INPUT REFS AND INPUT VARIABLES********************
  //Input data refs
  const inputDataRefs = {
    widthRef: useRef(null),
    depthRef: useRef(null),
    lengthRef: useRef(null),
    fyRef: useRef(null),
    fckRef: useRef(null),
    modulusOfElasticityOfSteelRef: useRef(null),
    loadTypeRef: useRef(null),
    loadRef: useRef(null),
    endConditionRef: useRef(null),
    coverRef: useRef(null),
    maxDiameterRef: useRef(null),
  };

  //Input Variables
  const inputData = {
    //geometric properties
    width: [
      "Geometric Properties",
      "Width",
      ["number", inputDataRefs.widthRef, 250],
      "mm",
    ],
    depth: [
      "Geometric Properties",
      "Depth",
      ["number", inputDataRefs.depthRef, 400],
      "mm",
    ],
    length: [
      "Geometric Properties",
      "Length",
      ["number", inputDataRefs.lengthRef, 2000],
      "mm",
    ],
    //material properties
    fy: [
      "Material Properties",
      "fy",
      ["number", inputDataRefs.fyRef, 415],
      "MPa",
    ],
    fck: [
      "Material Properties",
      "fck",
      ["number", inputDataRefs.fckRef, 30],
      "MPa",
    ],
    modulusOfElasticityOfSteel: [
      "Material Properties",
      "Modulus of Elasticity of Steel",
      ["number", inputDataRefs.modulusOfElasticityOfSteelRef, 200000],
      "MPa",
    ],
    //applied forces
    loadType: [
      "Applied Forces",
      "Load Type",
      ["dropdown", inputDataRefs.loadTypeRef, ["uniformLineLoad", "pointLoad"]],
      "",
    ],
    load: [
      "Applied Forces",
      "Applied Load",
      ["number", inputDataRefs.loadRef, 20],
      "",
    ],
    //setup information
    endCondition: [
      "Setup Information",
      "End Condition",
      [
        "dropdown",
        inputDataRefs.endConditionRef,
        ["fix-fix", "fix-pin", "pin-pin", "fix-free"],
      ],
      "",
    ],
    //reinforcement setup
    cover: [
      "Reinforcement Setup",
      "Cover",
      ["number", inputDataRefs.coverRef, 25],
      "mm",
    ],
    maxDiameter: [
      "Reinforcement Setup",
      "Maximum Diameter",
      ["number", inputDataRefs.maxDiameterRef, 25],
      "mm",
    ],
  };

  //********************DIAGRAMS********************
  //selected diagaram type state variable
  const [selectedDiagramType, setSelectedDiagramType] = useState(
    "longitudinalSectionFBD"
  ); //which diagram has been selected in the diagram section
  //diagram div state variables
  const diagramDivRef = useRef(null); // ref for the div containing the diagram
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 }); //dimensions of the div containing the diagram

  //resizing the diagram when the screen size changes
  useEffect(() => {
    const observeTarget = diagramDivRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      // Assuming you want to observe the first entry
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });
    // Step 2: Initialize the ResizeObserver
    if (observeTarget) {
      resizeObserver.observe(observeTarget);
    }

    // Step 3: Clean up
    return () => {
      if (observeTarget) {
        resizeObserver.disconnect();
      }
    };
  }, []); // Empty dependency array ensures this effect runs only once

  //handle dropdown change for selecting diagram
  const handleDropdownChange = (event) => {
    setSelectedDiagramType(event.target.value);
  };

  //********************CALCULATIONS********************
  //calculations related state variables
  const [designCalculationsVisibility, setDesignCalculationsVisibility] =
    useState(false); //whether calculations are shown or not
  const [designCalculations, setDesignCalculations] = useState({}); //to store the calcualation data we get from back end
  const [designCapacityRatios, setDesignCapacityRatios] = useState({}); //design capacity ratios object to generate design capacity ratios table

  //handle the clickdown of calculate button
  const calculateButtonClicked = async () => {
    //Create request object
    const requestObject = {};
    Object.keys(inputDataRefs).forEach((key) => {
      const keyName = key.replace(/Ref$/, ""); //Removing the "Ref" suffix from each of the key name
      requestObject[`${keyName}`] = inputDataRefs[key].current.value;
    });

    //Send data to back end
    axios
      .post(
        "/api/fetchRectangularSolidConcreteBeamDesignCalculations",
        requestObject,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setDesignCalculations(response.data["designCalculations"]); //Set design calculations state variable
        setDesignCapacityRatios(response.data["designCapacityRatios"]); //Set design capacity ratios to generate table
        setDesignCalculationsVisibility(true); //Set design calculations visibility
      })
      .catch((error) => {
        console.error(
          `The request could not be completed beacause of ${error}`
        );
      });
  };

  //********************SETTINGS MENU********************
  //settings menu state variables
  const [displaySettingsMenu, setDisplaySettingsMenu] = useState(false); //toggle to show or not show the settings menu
  const [designedBy, setDesignedBy] = useState("John Doe");
  const [checkedBy, setCheckedBy] = useState("Alice Alex");
  const [allowableDCR, setAllowableDCR] = useState(1);

  //hangle clickdown of settings button
  const settingsButtonClicked = () => {
    setDisplaySettingsMenu(true);
  };

  //********************PRINT BUTTON CLICKED********************
  //handle clickdown of print pdf button
  const printButtonClicked = async () => {
    if (designCalculationsVisibility) {
      //take current values from all input fields and prepare input data for PDF
      const inputDataForPDF = {};
      Object.keys(inputDataRefs).forEach((key) => {
        const keyName = key.replace(/Ref$/, "");
        inputDataForPDF[`${keyName}`] = [
          inputData[keyName][0],
          inputData[keyName][1],
          inputDataRefs[key].current.value,
          inputData[keyName][3],
        ];
      });

      //get the svg image that we have to convert (updateForModularity: new ids that have to printed out in the excel sheet)
      const longitudinalFBDURL = await convertSvgToPng(
        "longitudinalFBD",
        dimensions.height,
        dimensions.width
      );
      const sectionDiagramURL = await convertSvgToPng(
        "sectionDiagram",
        dimensions.height,
        dimensions.width
      );

      //generate pdf from blob
      const blob = await pdf(
        <MyDocument
          header={"Rectangular Hollow Steel Beam Design"}
          inputDataForPDF={inputDataForPDF} //current inputs that we have from the user
          calculationDataForPDF={designCalculations} //calculations data that we get from the backend
          designSummaryForPDF={designCapacityRatios} //design capacity ratios
          longitudinalFBDURL={longitudinalFBDURL}
          sectionDiagramURL={sectionDiagramURL}
          designedBy={designedBy}
          checkedBy={checkedBy}
        />
      ).toBlob();

      //create url to pdf blob
      const url = URL.createObjectURL(blob);

      //open pdf in new window
      window.open(url, "_blank");
    } else {
      alert(
        `Please design element by clicking "Calculate" before printing out the design sheet.`
      );
    }
  };

  //********************INFORMATION BUTTON CLICKED********************
  //handle the clickdown of information button (updateForModularity: show the new sheet for each design page)
  const informationButtonClicked = () => {
    window.open(
      "./assets/rectangularHollowSteelBeam/designManual/rectangularHollowSteelBeamDesignManual.pdf",
      "_blank"
    );
  };

  //********************INFORMATION OVERLAYS********************
  //information overlay state variables
  const [
    geometryInformationOverlayVisibility,
    setGeometryInformationOverlayVisibility,
  ] = useState(false);
  const [
    materialPropertiesInformationOverlayVisibility,
    setMaterialPropertiesInformationOverlayVisibility,
  ] = useState(false);
  const [
    appliedForcesInformationOverlayVisibility,
    setAppliedForcesInformationOverlayVisibility,
  ] = useState(false);
  const [
    setupInformationOverlayVisibility,
    setSetupInformationOverlayVisibility,
  ] = useState(false);

  //information overlay functions
  const geometryInformationButtonClicked = () => {
    setGeometryInformationOverlayVisibility(true);
  };

  const materialPropertiesInformationButtonClicked = () => {
    setMaterialPropertiesInformationOverlayVisibility(true);
  };

  const appliedForcesInformationButtonClicked = () => {
    setAppliedForcesInformationOverlayVisibility(true);
  };

  const setupInformationButtonClicked = () => {
    setSetupInformationOverlayVisibility(true);
  };

  const closeInformationOverlays = () => {
    setGeometryInformationOverlayVisibility(false);
    setMaterialPropertiesInformationOverlayVisibility(false);
    setAppliedForcesInformationOverlayVisibility(false);
    setSetupInformationOverlayVisibility(false);
  };

  const informationOverlayFunctions = [
    geometryInformationButtonClicked,
    materialPropertiesInformationButtonClicked,
    appliedForcesInformationButtonClicked,
    setupInformationButtonClicked,
  ];

  //********************TITLE BAR BUTTONS********************
  //title bar buttons
  const titleBarButtons = {
    calculateButton: ["Calculate", calculateButtonClicked],
    settingsButton: ["Settings", settingsButtonClicked],
    printButton: ["Print", printButtonClicked],
    informationButton: ["Information", informationButtonClicked],
  };

  return (
    <>
      <TitleBar
        titleBarTitle="Rectangular Solid Concrete Section"
        titleBarButtons={titleBarButtons}
      />
      <div id="container">
        {displaySettingsMenu && (
          <SettingsMenu
            setDisplaySettingsMenuFunction={setDisplaySettingsMenu}
            designedBy={designedBy}
            setDesignedBy={setDesignedBy}
            checkedBy={checkedBy}
            setCheckedBy={setCheckedBy}
            allowableDCR={allowableDCR}
            setAllowableDCR={setAllowableDCR}
          />
        )}
        {geometryInformationOverlayVisibility && (
          //updateForModularity: change name of source
          <InformationOverlay
            informationOverlayImageSrc="./assets/companyLogo.png"
            closeInformationOverlays={closeInformationOverlays}
          />
        )}
        {materialPropertiesInformationOverlayVisibility && (
          //updateForModularity: change name of source
          <InformationOverlay
            informationOverlayImageSrc="./assets/companyLogo.png"
            closeInformationOverlays={closeInformationOverlays}
          />
        )}
        {appliedForcesInformationOverlayVisibility && (
          //updateForModularity: change name of source
          <InformationOverlay
            informationOverlayImageSrc="./assets/companyLogo.png"
            closeInformationOverlays={closeInformationOverlays}
          />
        )}
        {setupInformationOverlayVisibility && (
          //updateForModularity change name of source
          <InformationOverlay
            informationOverlayImageSrc="./assets/companyLogo.png"
            closeInformationOverlays={closeInformationOverlays}
          />
        )}
        <div className="section" id="leftSection">
          <section>
            <h1 className="heading">Design Inputs</h1>
            <InputSheet
              inputData={inputData}
              updateCalculationsFunction={calculateButtonClicked}
              informationOverlayFunctions={informationOverlayFunctions}
            />
          </section>
        </div>
        <div className="section" id="middleSection">
          <div
            className="nested-section"
            id="nestedSection1"
            ref={diagramDivRef}
          >
            <h1 className="heading" style={{ margin: "0" }}>
              Diagrams
            </h1>
            {/*updateForModularity: show the respective diagram*/}
            {/* <svg className="diagramSVG" id="longitudinalFBD" style={{display: selectedDiagramType=="longitudinalSectionFBD" ? "block" : "none"}}>
                            <LongitudinalView frameLength={dimensions.width} 
                            frameHeight={dimensions.height} 
                            axialForce={inputDataRefs.axialForceRef.current ? inputDataRefs.axialForceRef.current.value : ""}
                            moment={inputDataRefs.momentAboutXRef.current ? inputDataRefs.momentAboutXRef.current.value : ""} />
                        </svg> */}
            {/*updateForModularity: show the respective diagram*/}
            {/* <svg className="diagramSVG" id="sectionDiagram" style={{display: selectedDiagramType=="sectionDimensions" ? "block" : "none"}}>
                            <SectionDimensions frameWidth={dimensions.width}
                            frameHeight={dimensions.height}
                            width={inputDataRefs.widthRef.current ? inputDataRefs.widthRef.current.value : ""}
                            height={inputDataRefs.heightRef.current ? inputDataRefs.heightRef.current.value : ""}
                            thickness={inputDataRefs.thicknessRef.current ? inputDataRefs.thicknessRef.current.value : ""} />
                        </svg> */}
            <div className="diagramDropdownDiv">
              <select
                id="diagramDropdown"
                value={selectedDiagramType}
                onChange={handleDropdownChange}
              >
                <option value="longitudinalSectionFBD">
                  Free Body Diagram
                </option>{" "}
                {/*updateForModularity: change value and the value to show*/}
                <option value="sectionDimensions">
                  Section Dimensions
                </option>{" "}
                {/*updateForModularity: change value and the value to show*/}
              </select>
            </div>
          </div>
          <div className="nested-section" id="nestedSection2">
            <h1
              className="heading"
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              Design Capacity and Ratios
            </h1>
            {!designCalculationsVisibility && (
              <div>Press calculate to show results</div>
            )}
            {designCalculationsVisibility && (
              <DesignCapacityAndRatiosTable
                data={designCapacityRatios}
                allowableDCR={allowableDCR}
              />
            )}
          </div>
        </div>
        <div className="section" id="rightSection">
          <h1 className="heading">Design Calculations</h1>
          {!designCalculationsVisibility && (
            <div>Press calculate to show results</div>
          )}
          {designCalculationsVisibility && (
            <section>
              <CalculationSheet calculationData={designCalculations} />
            </section>
          )}
        </div>
      </div>
    </>
  );
}

// Function to convert SVG to PNG
async function convertSvgToPng(svgID, height, width) {
  const svgElement = document.getElementById(svgID);
  const svgData = new XMLSerializer().serializeToString(svgElement);

  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const image = new Image(); // Use the standard Image constructor

    image.onload = function () {
      canvas.width = width;
      canvas.height = height;
      context.drawImage(image, 0, 0);

      // Convert canvas to data URL
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };

    image.onerror = function (error) {
      reject(error);
    };

    image.src = "data:image/svg+xml;base64," + btoa(svgData);
  });
}

export default RectangularSolidConcreteBeam; //updateForModularity: change the name of the function that is being exported
