import "./textButton.css"

function TextButton({text, onClickFunction})
{
    return(
        <>
            <button className="textButton" onClick={onClickFunction}>{text}</button>
        </>
    );
}

export default TextButton;