import { Link } from "react-router-dom";
import "./productTile.css"

function ProductTile({productImageSrc, accessStatus, productTitle, linkTo})
{
    return(
        <>
            <Link className="link" to={linkTo}>
                <div className="productTile">
                    <div className="productImage">
                        <img className="image" src={productImageSrc}/>
                        <div className="accessStatus">{accessStatus}</div>
                    </div>
                    <div className="productTitle">
                        <p>{productTitle}</p>
                    </div>
                </div>
            </Link>
        </>
    );
}

function ProductTiles({products})
{
    const renderedProductCatalog = [];
    Object.keys(products).forEach((key) => {
        renderedProductCatalog.push(<ProductTile productImageSrc={products[key][0]} accessStatus={products[key][1]} productTitle={products[key][2]} linkTo={products[key][3]}/>);
    });

    return (
        <>
            {renderedProductCatalog}
        </>
    )
}

export default ProductTiles;