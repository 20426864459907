//Here originX and originY are the center of the circle

function Circle({originX, originY, radius, fill, stroke}) {
    return (
        <>
            <path d={`M ${originX},${originY - radius}
                A ${radius}, ${radius}
                0 1 0
                ${originX}, ${originY + radius}
                A ${radius}, ${radius}
                0 1 0
                ${originX}, ${originY-radius}`}
                fill={fill}
                stroke = {stroke} />
        </>
    );
}

export default Circle;