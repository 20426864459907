import TextButton from "../textButton/textButton";
import "./titleBar.css"

function TitleBar({titleBarTitle, titleBarButtons}) {
    //render title bar buttons
    const renderedTitleBarButtons = [];
    Object.keys(titleBarButtons).forEach((key) => {
        renderedTitleBarButtons.push(<TextButton text={titleBarButtons[key][0]}
            onClickFunction={titleBarButtons[key][1]} />);
    })
    
    return (
        <div className="_titleBar">
            <div className="_titleBarTitle">
                <img className="_titleBarImage" src="./assets/companyLogo.png" />
                <div className="_titleBarText">{titleBarTitle}</div>
            </div>
            <div className="_titleBarButtons">
                {renderedTitleBarButtons}
            </div>
        </div>
    )
}

export default TitleBar;