import "./informationOverlay.css"

function InformationOverlay({informationOverlayImageSrc, closeInformationOverlays}) {
    return (
        <div className="_informationOverlayDiv" onClick={closeInformationOverlays}>
            <img src= {informationOverlayImageSrc} />
        </div>
    );
}

export default InformationOverlay;