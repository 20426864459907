import "./calculationSheet.css"

function CalculationDataEntry({calculationDataEntryTitle, calculationDataEntryValue, calculationDataEntryUnit}) {
    return (
        <div className="_calculationDataEntryDiv">
            <label className="_calculationDataEntryTitle">{calculationDataEntryTitle}</label>
            <label className="_calculationDataEntryValue">{calculationDataEntryValue}</label>
            <label className="_calculationDataEntryUnit">{calculationDataEntryUnit}</label>
        </div>
    )
}

function CalculationSheet({calculationData}) {
    //Format for Calculation Data
    // const calculationData = {
    //     [key]: ["Heading", "Label Title", "Value", "Unit"]
    // }

    //Get out all the headings from the calculation data. The "...new Set" method is going to remove any duplicates and keep only the unique ones
    let allHeadings = [];
    Object.keys(calculationData).forEach((key) => {
        allHeadings.push(calculationData[key][0]);
    })

    let uniqueHeadings = [...new Set(allHeadings)];

    //put the rendered data entries into the temp object for rendering
    const renderedCalculationSheet = [];

    uniqueHeadings.map((heading) => {
        renderedCalculationSheet.push(<h1 className="_calculationHeading">{heading}</h1>);
        Object.keys(calculationData).forEach((key) => {
            if(calculationData[key][0]==heading) {
                renderedCalculationSheet.push(<CalculationDataEntry calculationDataEntryTitle={calculationData[key][1]} calculationDataEntryValue={calculationData[key][2]} calculationDataEntryUnit={calculationData[key][3]} />);
            }
        })
    });

    //Return the entire calculation sheet as one calculation div
    return (
        <>
            {renderedCalculationSheet}
        </>
    )
}

export default CalculationSheet;