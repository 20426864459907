import "./homePage.css";
import ProductTiles from "../../components/productTile/productTile";
import TitleBar from "../../components/titleBar/titleBar";
import axios from "axios";

async function loginButtonClicked() {
  //Async allows the function to be asnychornous. Which mean that even though the computer might be able to proceed with a task at its own pace, it won't until the await statement code is resolved.
  try {
    const response = await axios.get("/api/loginRequest"); //Using the await keyword, we are telling the compiler to not move forward until and unless this statement is resolved, or the data that is required is received.

    console.log(response);
  } catch (error) {
    console.log(`this request could not be completed because of ${error}`);
  }
}

function communityButtonClicked() {
  console.log("community button has been clicked so take care of that");
}

function articlesButtonClicked() {
  console.log("articles button was clicked so you have to handle that");
}

const titleBarButton = {
  articlesButton: ["Articles", articlesButtonClicked],
  communityButton: ["Community", communityButtonClicked],
  loginButton: ["Login", loginButtonClicked],
};

function HomePage() {
  const products = {
    rectangularHollowSteelBeam: [
      "./assets/rectangularHollowSteelBeam/homePageImage.png",
      "FREE",
      "Rectangular Hollow Steel Beam",
      "/rectangularHollowSteelBeam",
    ],
    circularHollowSteelBeam: [
      "./assets/circularHollowSteelBeam/homePageImage.png",
      "FREE",
      "Circular Hollow Steel Beam",
      "/circularHollowSteelBeam",
    ],
    rectangularSolidConcreteBeam: [
      "",
      "FREE",
      "Rectangular Solid Concrete Beam",
      "/rectangularSolidConcreteBeam",
    ],
  };

  return (
    <>
      <TitleBar
        titleBarTitle="Structura Pro"
        titleBarButtons={titleBarButton}
      />
      <div className="captionBar">
        <h1 className="mainCaption">Think . Design . Print </h1>
        <h3 className="subCaption">
          Enabling Structural Engineers, Civil Engineers and Architects to
          Design on the Fly
        </h3>
      </div>
      <div className="searchBar">
        <input className="searchField" type="text" placeholder="Search..." />
      </div>
      <div className="productCatalog" id="productCatalog">
        <ProductTiles products={products} />
      </div>
    </>
  );
}

export default HomePage;
