import Circle from "../elements/circle";
import DimensionLine from "../elements/dimensionLine";

function SectionDimensions({frameWidth, frameHeight, radius, thickness}) {
    const frameCenterX = frameWidth/2;
    const frameCenterY = frameHeight/2 + 20;
    const displayRadius = frameHeight*0.3;
    const displayThickness = 10;

    return (
        <>  
            {/*Outer Circle*/}
            <Circle originX={frameCenterX}
                originY={frameCenterY}
                radius={displayRadius+displayThickness}
                fill="lightgrey"
                stroke="black"/>
            {/*Inner Circle*/}
            <Circle originX={frameCenterX}
                originY={frameCenterY}
                radius={displayRadius}
                fill="white"
                stroke="black"/>
            {/*Radius Dimension Line*/}
            <DimensionLine originX={frameCenterX+displayRadius/2}
                originY={frameCenterY}
                spanDirection="horizontal"
                span={displayRadius}
                text={`${radius} mm`}
                textAlignment="top"
                stroke="black"/>
            {/*Thickness Dimension Line*/}
            <DimensionLine originX={frameCenterX}
                originY={frameCenterY-displayRadius-displayThickness/2}
                spanDirection="vertical"
                span={displayThickness}
                text={`${thickness} mm`}
                textAlignment="top"
                stroke="black"/>
        </>
    );
}

export default SectionDimensions;