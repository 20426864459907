import Rectangle from "./rectangle";

//format for the items array has to be as follows
// const legendItems = {
//     [key]: ["fill", "text"]
// }

function LegendLineItem({originX, originY, sideLength, stroke, fill, text}) {
    return (
        <>
            <Rectangle originX={originX} 
                originY={originY} 
                width={sideLength} 
                height={sideLength} 
                stroke={stroke} 
                fill={fill} />
            <text x={originX + sideLength/2 + 10}
                y={originY}
                fill="black"
                dominantBaseline="middle"
                textAnchor="left"
                fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif">{text}</text>
        </>
    );
}

function Legend({originX, originY, sideLength,
    verticalSpacing, items}) {
    const renderedLegend = [];
    let counter = 0;
    Object.keys(items).forEach((key) => {
        renderedLegend.push(<LegendLineItem originX={originX}
            originY = {originY + counter*verticalSpacing}
            sideLength={sideLength}
            stroke={items[key][0]}
            fill={items[key][0]}
            text={items[key][1]}/>)
        counter++;
    });

    return (
        <>
            {renderedLegend}
        </>
    );
}

export default Legend;