//here originX and origiY means the center of the circle of the arrow

function CircularArrow({originX, originY, radius, arrowHeadHeight, stroke, fill, rotation, orientation}) {
    if(rotation == "clockwise" && orientation == "left") {
        return (
            <>
                <path d={`M ${originX},${originY-radius} 
                    A ${radius},${radius} 
                    0 0 0 
                    ${originX},${originY+radius}`}
                    fill="none"
                    stroke={stroke}/>
                <polygon points={`${originX},${originY-radius-arrowHeadHeight/2} ${originX},${originY-radius+arrowHeadHeight/2} ${originX+arrowHeadHeight},${originY-radius}`} 
                    stroke={stroke}
                    fill={fill}/>
            </>
        );
    } else if (rotation == "clockwise" && orientation == "right") {
        return (
            <>
                <path d={`M ${originX},${originY-radius}
                    A ${radius},${radius} 
                    0 0 1 
                    ${originX},${originY+radius}`}
                    fill="none"
                    stroke={stroke}/>
                <polygon points={`${originX},${originY+radius-arrowHeadHeight/2} 
                    ${originX},${originY+radius+arrowHeadHeight/2} 
                    ${originX-arrowHeadHeight},${originY+radius}`} 
                    stroke={stroke} 
                    fill={fill}/>
            </>
        );
    } else if (rotation == "antiClockwise" && orientation == "left") {
        return (
            <>
                <path d={`M ${originX},${originY-radius}
                    A ${radius},${radius} 
                    0 0 1
                    ${originX},${originY+radius}`}
                    fill="none"
                    stroke={stroke}/>
                <polygon points={`${originX},${originY+radius-arrowHeadHeight/2} 
                    ${originX},${originY+radius+arrowHeadHeight/2} 
                    ${originX-arrowHeadHeight},${originY+radius}`} 
                    stroke="green" 
                    fill="green"/>
            </>
        );
    } else { //this is anticlockwise right
        return (
            <>
                <path d={`M ${originX},${originY+radius} 
                    A ${radius}, ${radius} 
                    0 0 0 ${originX},${originY-radius}`}
                    fill="none"
                    stroke={stroke}/>
                <polygon points={`${originX},${originY-radius+arrowHeadHeight/2} 
                    ${originX},${originY-radius-arrowHeadHeight/2} 
                    ${originX-arrowHeadHeight},${originY-radius}`} 
                    stroke={stroke}
                    fill={fill}/>
            </>
        );
    }
}

export default CircularArrow;