import "./designCapacityAndRatios.css"

function TableRow({item1, item2, item3, item4}) {
    let rowColour = "";
    if(item4>1) {
        rowColour = "red";
    } else {
        rowColour = "green";
    }

    return (
        <>
            <tr style={{color: `${rowColour}`}}>
                <td>{item1}</td>
                <td>{item2}</td>
                <td>{item3}</td>
                <td style={{textAlign: "right"}}>{item4}</td>
            </tr>
        </>
    );
}

function DesignCapacityAndRatiosTable({data, allowableDCR}) {
    const tableData = [];
    Object.keys(data).forEach((key) => {
        tableData.push(<TableRow item1={data[key][0]} item2={data[key][1]} item3={data[key][2]} item4={data[key][3]} allowableDCR = {allowableDCR}/>)
    })
    
    return (
        <table>
            <thead>
                <tr>
                    <th>Description</th>
                    <th>Applied Force</th>
                    <th>Capacity</th>
                    <th style={{textAlign: "right"}}>Capacity Ratio</th>
                </tr>
            </thead>
            <tbody>
                {tableData}
            </tbody>
        </table>    
    );
}

export default DesignCapacityAndRatiosTable;